

.searchBar {
  width: 70%;
}

.clear-icon {
  cursor: pointer;
}

@media (max-width: 480px) {
  .searchBar {
    width: 100%;
  }
}

.app-name {
  font-size: 18px;
  cursor: pointer;
}


.appIcon img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pageTitle {
  margin-right: 30px;

}



.admin-container {
  text-align: left;
}

.app-container {
  text-align: right;
}

.nav {
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

.nav li {
  margin: 0 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 4px;
}

.nav li:hover {
  background-color: #f0f0f0;
}

.nav li.active {
  background-color: #007BFF;
  color: white;
  
}

.nav .active {
  /*background-color: #007BFF;*/
  color: white;
  
}

.nav a {
  color: black;
  text-decoration: none;
}

.nav li a:hover {
  text-decoration: underline;
}

.nav li a:visited {
  color: black; 
}

.nav li.active a {
  color: white;
  text-decoration: none;
}



/* Style for the tool cards */
.artifact-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  
  max-width: 100%;
  overflow-x: auto;
}


/* Style for the tool cards */
.tool-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  overflow-x: auto;
}

/* Style for the property table */
.property-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  table-layout: auto; /* Set table layout to auto */
}

/* Style for property rows */
.property-row {
  border-bottom: 1px solid #ccc; /* Add a border to separate rows */
}

/* Style for property names */
.property-name {
  padding: 8px;
  font-weight: bold;
  border-right: 1px solid #ccc; /* Add a right border to separate columns */
  background-color: #f5f5f5; /* Shade the left column */
  width: 1%; /* Set the width to 1% for the left column */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis for long text */
}

/* Style for property values */
.property-value {
  padding: 8px;
  background-color: #ffffff;
}

/* Style for highlighted elements */
.highlight {
  background-color: lightcoral; /* Set the background color to light red */
}


/* style for scroll to top button */
.scrollTop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 24px;
}
